import { createStore, combineReducers, applyMiddleware } from "redux";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  user: {},
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case "set_user":
      return { ...state, ...rest };
    default:
      return state
  }
}

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  changeState,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(thunk))
  );
  let persistor = persistStore(store);
  return { store, persistor };
};