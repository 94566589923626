import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { removeLocalStorage } from "../utils/localStorage";
import {WEB_API} from "../env"
axios.defaults.baseURL = WEB_API;
//axios.defaults.baseURL = process.env.REACT_APP_PORT;


axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log('error401::')
      removeLocalStorage("token");
      window.location.href = "/#/login";
    }
    throw error;
  }
);
